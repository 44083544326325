import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
const Footer = () => {
  const navigate = useNavigate();

  const gotoAboutUs = () => {
    navigate(`/about-us`);
  };
  return (
    <footer className="container-fluid">
      <div className="footer-main">
        <div className="footer-menu-main">
          <ul className="footer-menu">
            <li onClick={gotoAboutUs}>About</li>
            <li>Contact Us</li>
            <li>Privacy Policy</li>
            <li>Terms & Condition</li>
          </ul>
          <div className="copy-right-section">
            TRUE FITNESS AI
            <br />
            All rights reserved
            <br />
            ©2023 
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
