import React, { useEffect, useState } from 'react'
import logo from "../assets/images/logo.svg"
import Dropdown from 'react-bootstrap/Dropdown';
import MenuBar from "../assets/images/menu-bar.svg"
import { Button, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import "./Header.scss"
import JoinFamilyModal from './Modal/JoinFamilyModal';
const Header = () => {
    const [showModal, setShowModal] = useState(false);
    const [show, setShow] = useState(false);
    const CloseModal = () => setShowModal(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const gotoHome = () => {
        navigate('/')
    }
    const gotoAboutUs = () => {
        navigate(`/about-us`);
    };
    useEffect(() => {
        const getStartedBtn = document.getElementById("getStartedBtn");
        const aboutBtn = document.getElementById("aboutBtn");
        const supportBtn = document.getElementById("supportBtn");
        const logo = document.getElementById("logo");
        function changeBackground(event) {
            const active = document.querySelector(".active");
            if (active) {
                active.classList.remove("active");
            }
            event.target.className = "active";
        }

        getStartedBtn.addEventListener("click", changeBackground.bind(this));
        aboutBtn.addEventListener("click", changeBackground.bind(this));
        supportBtn.addEventListener("click", changeBackground.bind(this));
        logo.addEventListener("click", changeBackground.bind(this));
    }, []);

    return (
        <>
            <JoinFamilyModal show={showModal} handleClose={CloseModal} />
            <header className="container-fluid" id="header">
                <div className="navigation-section ">
                    <div className="logo" id='logo' onClick={gotoHome}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="menu-main">
                        <ul className="menu">
                            <li id='getStartedBtn'>Get Started</li>
                            <li onClick={gotoAboutUs} id='aboutBtn'>About</li>
                            <li id='supportBtn'><Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Support
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item >Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></li>
                            <li>
                                <Button className='join-btns' onClick={() => setShowModal(true)}>JOIN WAITLIST</Button>
                            </li>
                        </ul>
                    </div>
                    <div className="mobile-nav-main">
                        <button onClick={handleShow} id='mobile-bar-btn' aria-label="Navigation">
                            <img src={MenuBar} alt="" />
                        </button>
                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <div className="offcanvas-logo">
                                        <img src={logo} alt="" />
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="mobile-menu-side">
                                    <ul className="mobile-menu-main">
                                        <li>Get Started</li>
                                        <li onClick={() => {
                                            gotoAboutUs();
                                            handleClose();
                                        }}>About</li>
                                        <li><Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                Support
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown></li>
                                    </ul>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header