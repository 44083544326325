import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'
import "./JoinFamilyModal.scss"
const JoinFamilyModal = ({ show, handleClose }) => {
    return (
        <Modal
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
            className='modal-main'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>JOIN THE <br/>FAMILY!</h4>
                <p>
                    Power your pursuit with True Fitness AI -
                    Wherever you are, whenever you’re ready.

                </p>
                <div className="trial-input">
                    <input type="text" name="" id="" placeholder='Start your complimentary 7-day trial now.' />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button>Subscribe</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JoinFamilyModal